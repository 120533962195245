import React from 'react';
import styled from 'styled-components';

import ActionBar from './ActionBar';

const HeroContainer = styled.div`
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const VideoBackground = styled.video`
  width: 100%;
  height: 550px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0 20px;
  z-index: 1;

  h2 {
    font-size: 80px;
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    line-height: 1;
    max-width: 800px;
  }

  p {
    font-size: 40px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }
`;

const titles = {
  home: '#1 Rated Pressure Washing Company',
  about: 'About Us',
  gallery: 'Gallery',
  reviews: 'Reviews',
  housewashing: 'House Washing',
  roofwashing: 'Roof Washing',
  windowwashing: 'Window Washing',
  gutterbrightening: 'Gutter Brightening',
  concretecleaning: 'Concrete Cleaning',
  deckandfence: 'Deck & Fence Cleaning',
  concretedegreasing: 'Concrete Degreasing',
  buildingrestoration: 'Building Restoration',
  graffitirustremoval: 'Graffiti & Rust Removal',
};

const subtitles = {
  home: '5 Star Rating with Over 250 Reviews',
  about: 'Your Trusted Pressure Washing Company',
  gallery: 'See Our Work',
  reviews: 'What Our Clients Have to Say',
  housewashing: 'Clean and Renew Your Home',
  roofwashing: "Roof Stains? We've Got You Covered",
  windowwashing: 'Our Expert Window Cleaning Services',
  gutterbrightening: 'Professional Gutter Brightening',
  concretecleaning: 'Clean and Protect Your Property',
  deckandfence: 'Feel Clean and Comfortable Outdoors',
  concretedegreasing: 'Clean and Preserve Your Business',
  buildingrestoration: 'Revitalize Your Building',
  graffitirustremoval: 'Present Your Business in the Best Light',
};

const Hero = ({ page, changePage, setIsQuoteFormOpen }) => {
  if (page.includes('services/')) page = page.replace('services/', '');

  return (
    <>
      <HeroContainer>
        <VideoBackground autoPlay loop muted playsInline>
          <source src='https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/hero_movie.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </VideoBackground>

        <Overlay>
          <h2>{titles[page]}</h2>
          <p>{subtitles[page]}</p>
        </Overlay>
      </HeroContainer>
      <ActionBar page={page} changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} />
    </>
  );
};

export default Hero;