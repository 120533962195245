import React, { useState } from "react";
import styled from "styled-components";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

import colors from "../colors";
import cleanchoicepw from "../images/cleanchoicepw.svg";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
`;

const Branding = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
`;

const Logo = styled.img`
  height: 125px;
  width: auto;
  margin: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 100px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PhoneButton = styled.a`
  padding: 5px 20px;
  background-color: ${colors.secondaryBlue};
  border: 3px solid ${colors.primaryBlue};
  color: white;
  border-radius: 50px;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: ${colors.primaryBlue};
  }

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const QuoteButton = styled.button`
  padding: 5px 20px;
  background-color: ${colors.primaryOrange};
  border: 3px solid ${colors.secondaryOrange};
  color: white;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #cc7a00;
  }

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const IconGroup = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    top: 10px;
  }
`;

const StyledFontawesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 40px;
  margin-right: 10px;

  &:hover {
    color: ${colors.primaryOrange};
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  padding: ${(props) => (props.padding ? "4px 10px" : "0 10px")};
  border-left: ${(props) => (props.borderLeft ? `1px solid ${colors.darkBlue}` : "none")};
  cursor: pointer;

  &:hover {
    background: ${colors.darkBlue};
  }
`;

const ServiceDropdown = styled(Dropdown)`
  background-color: ${colors.primaryBlue};
`;

const ServiceItem = styled(Dropdown.Item)`
  color: white;
  cursor: pointer;

  &:hover {
    background: ${colors.darkBlue};
    color: white;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const ServiceDropdownMenu = styled(Dropdown.Menu)`
  background-color: ${colors.primaryBlue};
  border: none;
`;

const StyledNavbar = styled(Navbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.primaryBlue};
  padding: 10px 0;
  position: relative;
`;

const StyledNavCollapse = styled(Navbar.Collapse)`
  @media (max-width: 991px) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: ${colors.primaryBlue};
    padding: 20px;
    z-index: 10;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledNavToggle = styled(Navbar.Toggle)`
  background-color: #304c88;
  border: none;
`;

const Header = ({ changePage, setIsQuoteFormOpen }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleNavToggle = () => setIsNavExpanded(!isNavExpanded);

  const closeNav = () => setIsNavExpanded(false);

  const pageChanger = (page) => (e) => {
    e.preventDefault();
    changePage(page);
    closeNav(); // Close the menu after selecting a page
  };

  return (
    <HeaderContainer>
      <Branding>
        <Logo onClick={() => changePage("home")} src={cleanchoicepw} alt="Clean Choice Pressure Washing" />
        <ButtonsContainer>
          <PhoneButton href="tel:+12198519299">(219) 851-9299</PhoneButton>
          <QuoteButton onClick={() => setIsQuoteFormOpen(true)}>Get a quote!</QuoteButton>
        </ButtonsContainer>
      </Branding>

      <StyledNavbar variant="dark" expand="lg" expanded={isNavExpanded}>
        <Container>
          <StyledNavToggle aria-controls="basic-navbar-nav" onClick={handleNavToggle} />
          <StyledNavCollapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <ServiceDropdown>
                <Dropdown.Toggle padding as={StyledLink}>
                  Services
                </Dropdown.Toggle>
                <ServiceDropdownMenu>
                  <ServiceItem href="/services/house" onClick={pageChanger("services/housewashing")}>
                    House Washing
                  </ServiceItem>
                  <ServiceItem href="/services/roof" onClick={pageChanger("services/roofwashing")}>
                    Roof Washing
                  </ServiceItem>
                  <ServiceItem href="/services/window" onClick={pageChanger("services/windowwashing")}>
                    Window Washing
                  </ServiceItem>
                  <ServiceItem href="/services/gutter" onClick={pageChanger("services/gutterbrightening")}>
                    Gutter Brightening
                  </ServiceItem>
                  <ServiceItem href="/services/concretecleaning" onClick={pageChanger("services/concretecleaning")}>
                    Concrete Cleaning
                  </ServiceItem>
                  <ServiceItem href="/services/deckandfence" onClick={pageChanger("services/deckandfence")}>
                    Deck & Fence Cleaning
                  </ServiceItem>
                  <ServiceItem href="/services/buildingrestoration" onClick={pageChanger("services/buildingrestoration")}>
                    Building Restoration
                  </ServiceItem>
                  <ServiceItem href="/services/graffitirustremoval" onClick={pageChanger("services/graffitirustremoval")}>
                    Graffiti & Rust Removal
                  </ServiceItem>
                </ServiceDropdownMenu>
              </ServiceDropdown>
              <StyledLink borderLeft href="/gallery" onClick={pageChanger("gallery")}>
                Gallery
              </StyledLink>
              <StyledLink
                borderLeft
                href="https://search.google.com/local/reviews?placeid=ChIJz0ajem4TEYgRDPeWqND_hYM&q=Clean+Choice+Pressure+Washing&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reviews
              </StyledLink>
              <StyledLink borderLeft href="/about" onClick={pageChanger("about")}>
                About Us
              </StyledLink>
              <StyledLink borderLeft onClick={() => setIsQuoteFormOpen(true)}>
                Get A Quote
              </StyledLink>
            </Nav>
          </StyledNavCollapse>
          <IconGroup>
            <Nav.Link href="https://www.facebook.com/CleanChoice87" target="_blank" rel="noreferrer" aria-label="Facebook">
              <StyledFontawesomeIcon icon={faFacebook} />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/cleanchoicepw" target="_blank" rel="noreferrer" aria-label="Instagram">
              <StyledFontawesomeIcon icon={faInstagram} />
            </Nav.Link>
          </IconGroup>
        </Container>
      </StyledNavbar>
    </HeaderContainer>
  );
};

export default Header;