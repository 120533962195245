import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const GutterBrightening = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Restores Original Appearance",
      description:
        "Professional gutter brightening removes stains, streaks, and dirt, restoring your gutters to their original, clean look.",
    },
    {
      title: "Enhances Curb Appeal",
      description:
        "Brightened gutters complement your home's exterior, creating a polished and well-maintained appearance that stands out.",
    },
    {
      title: "Prevents Permanent Staining",
      description:
        "Removing buildup and discoloration early prevents permanent staining, keeping your gutters looking newer for longer.",
    },
    {
      title: "Improves Longevity",
      description:
        "Regular cleaning and brightening help protect your gutters from corrosion and degradation caused by dirt and debris.",
    },
    {
      title: "Increases Home Value",
      description:
        "Well-maintained gutters contribute to your home's overall value, making it more attractive to potential buyers.",
    },
    {
      title: "Professional-Grade Results",
      description:
        "Our expertise ensures your gutters are brightened thoroughly and safely, without damage to the gutter material or surrounding areas.",
    },
  ];

  return (
    <ServiceTemplate
      title="Brighten Your Home’s Exterior"
      subtitle="Our Gutter Brightening services remove tough stains and discoloration, restoring your gutters to their original shine and enhancing your home's curb appeal."
      seeMoreLink="/gallery"
      sectionTitle="Why Invest in Gutter Brightening?"
      sectionDescription="Gutters often accumulate stains and streaks that can detract from the beauty of your home. Gutter brightening not only improves the appearance of your home but also protects and prolongs the life of your gutters. Here’s why it’s essential:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gutter_brightening.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default GutterBrightening;