import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const HouseWashing = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Boosts Curb Appeal",
      description:
        "House washing removes dirt, algae, and mildew, leaving your home looking clean and vibrant, perfect for enhancing curb appeal.",
    },
    {
      title: "Protects Exterior Surfaces",
      description:
        "Regular washing prevents the buildup of harmful elements that can degrade paint, wood, and siding, extending the life of your home's exterior.",
    },
    {
      title: "Prepares for Painting or Repairs",
      description:
        "A clean exterior ensures better adhesion for paint and simplifies the preparation process for any upcoming repairs or renovations.",
    },
    {
      title: "Improves Health and Hygiene",
      description:
        "Eliminating mold, mildew, and allergens from your home's exterior promotes a healthier environment for you and your family.",
    },
    {
      title: "Saves Time and Effort",
      description:
        "Professional house washing is efficient and thorough, saving you the time and hassle of scrubbing your home's exterior yourself.",
    },
    {
      title: "Maintains Property Value",
      description:
        "A clean and well-maintained home exterior helps retain or even increase property value, making it more attractive to potential buyers.",
    },
  ];

  return (
    <ServiceTemplate
      title="Refresh Your Home's Appearance"
      subtitle="Our professional House Washing services are designed to restore the beauty and cleanliness of your home's exterior. Say goodbye to grime, mold, and stains with our efficient and thorough cleaning solutions."
      seeMoreLink="/gallery"
      sectionTitle="Why Choose Professional House Washing?"
      sectionDescription="House washing goes beyond just aesthetics—it's a crucial part of maintaining your home's structural integrity and creating a healthy living environment. Here's how our house washing services can benefit you:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/house_washing.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default HouseWashing;