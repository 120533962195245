import React from "react";
import styled, { keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
import colors from "../colors";

const wobble = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
`;

const ActionBarContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  background: ${colors.primaryBlue};
  padding: 15px 0;
  width: 100%;

  p {
    color: white;
    font-size: 24px;
    font-weight: bold;
    z-index: 3;
    animation: ${({ isWobbling }) => isWobbling ? wobble : "none"} 0.6s ease-in-out;
  }

  @media (max-width: 768px) {
    padding: 10px 0;

    p {
      font-size: 18px;
    }
  }
`;

const ActionBarButton = styled.a`
  padding: 10px 20px;
  background-color: ${colors.secondaryBlue};
  border: none;
  color: white;
  border-radius: 10px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  z-index: 3;

  &:hover {
    background-color: ${colors.darkBlue};
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Dongle = styled.div`
  position: absolute;
  top: 725px;
  transform: rotate(45deg);
  background: ${colors.primaryBlue};
  height: 100px;
  width: 100px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ActionBar = ({ dongle = true, setIsQuoteFormOpen }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <ActionBarContainer ref={ref} isWobbling={inView}>
      {dongle && <Dongle />}
      <p>Get A Free Quote!</p>
      <ActionBarButton onClick={() => setIsQuoteFormOpen(true)}>Get Started</ActionBarButton>
    </ActionBarContainer>
  );
};

export default ActionBar;
