import React, { useState } from "react";
import styled from "styled-components";
import colors from "../colors";
import { PageContainer } from "../components/Reusables";
import ActionBar from "../components/ActionBar";

const SectionContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
`;

const SectionHeader = styled.div`
  padding: 15px;
  background: ${colors.primaryOrange};
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Chevron = styled.span`
  font-size: 16px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImagePlaceholder = styled.div`
  background-color: ${({ color }) => color || "#ccc"};
  width: 100%;
  height: 400px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;

const RealImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const GalleryPage = ({ changePage, setIsQuoteFormOpen }) => {
  const [openSections, setOpenSections] = useState({
    houseWashing: true,
    concreteCleaning: true,
    roofWashing: true,
    deckFenceCleaning: true,
    gutterBrightening: true,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const sections = [
    {
      name: "House Washing", key: "houseWashing", images: [
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_1.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_2.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_3.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_4.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_5.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_6.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_7.jpg",
        "https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/house_washing_8.jpg",
      ],
    },
    {
      name: "Concrete Cleaning", key: "concreteCleaning", images: [
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/concrete_cleaning_1.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/concrete_cleaning_2.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/concrete_cleaning_3.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/concrete_cleaning_4.jpg',
      ]
    },
    {
      name: "Roof Washing", key: "roofWashing", images: [
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/roof_washing_1.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/roof_washing_2.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/roof_washing_3.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/roof_washing_4.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/roof_washing_5.jpg',
      ]
    },
    {
      name: "Deck & Fence Cleaning", key: "deckFenceCleaning", images: [
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/deck_fence_cleaning_1.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/deck_fence_cleaning_2.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/deck_fence_cleaning_3.JPEG',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/deck_fence_cleaning_4.jpg',
      ]
    },
    {
      name: "Gutter Brightening", key: "gutterBrightening", images: [
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/gutter_brightening_1.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/gutter_brightening_2.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/gutter_brightening_3.jpg',
        'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gallery/gutter_brightening_4.jpg',
      ]
    }
  ];

  return (
    <PageContainer>
      {sections.map((section) => (
        <SectionContainer key={section.key}>
          <SectionHeader onClick={() => toggleSection(section.key)}>
            {section.name}
            <Chevron isOpen={openSections[section.key]}>▼</Chevron>
          </SectionHeader>
          {openSections[section.key] && (
            <GalleryGrid>
              {section.images
                ? section.images.map((image, index) => (
                  <RealImage
                    key={index}
                    src={image}
                    alt={`${section.name} - Image ${index + 1}`}
                  />
                ))
                : Array.from({ length: 4 }).map((_, index) => (
                  <ImagePlaceholder
                    key={index}
                    color="#ccc"
                    title={`${section.name} - Placeholder Image ${index + 1}`}
                  />
                ))}
            </GalleryGrid>
          )}
        </SectionContainer>
      ))}
      <ActionBar
        dongle={false}
        changePage={changePage}
        setIsQuoteFormOpen={setIsQuoteFormOpen}
      />
    </PageContainer>
  );
};

export default GalleryPage;