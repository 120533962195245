import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import colors from "../colors";
import { PageContainer } from "../components/Reusables";
import ActionBar from "../components/ActionBar";
import Reviews from "../components/Reviews";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  color: ${colors.primaryOrange};
  font-weight: bold;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 40px;
  max-width: 800px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  align-items: center;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || colors.offWhite};
  text-align: center;
  padding: 40px 20px;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
  color: ${({ color }) => color || colors.primaryOrange};
`;

const SectionDescription = styled.p`
  font-size: 20px;
  color: #ffffff;
  line-height: 1.5;
  margin-bottom: 40px;
  max-width: 800px;
`;

const ExampleImage = styled.img`
  max-width: 900px;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const BenefitsContainer = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  max-width: 900px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  align-items: center;
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background: ${colors.darkBlue};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${colors.primaryOrange};
  margin-bottom: 10px;
`;

const BenefitContent = styled.div`
  text-align: left;
`;

const BenefitTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-bottom: 2px solid ${colors.primaryBlue};
  padding-bottom: 10px;
  width: 100%;
`;

const BenefitDescription = styled.p`
  font-size: 16px;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
`;

const SeeMoreButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  color: white;
  background-color: ${colors.primaryOrange};
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;

  &:hover {
    background-color: ${colors.secondaryOrange};
  }

  svg {
    margin-left: 10px;
  }
`;

const ServiceTemplate = ({
  title,
  subtitle,
  seeMoreLink,
  sectionTitle,
  sectionDescription,
  exampleImage,
  benefits,
  changePage,
  setIsQuoteFormOpen
}) => {
  return (
    <PageContainer>
      <Container>
        <Section>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {exampleImage && <ExampleImage src={exampleImage} alt={`${title} example`} />}
          {seeMoreLink && (
            <SeeMoreButton
              href={seeMoreLink}
              onClick={(e) => {
                e.preventDefault();
                changePage(seeMoreLink.replace("/", ""));
              }}
            >
              See more of our work <FontAwesomeIcon icon={faArrowRight} />
            </SeeMoreButton>
          )}
        </Section>
        <Section bgColor={colors.primaryBlue}>
          <SectionContent>
            <SectionTitle color='#ffffff'>{sectionTitle}</SectionTitle>
            <SectionDescription>{sectionDescription}</SectionDescription>
          </SectionContent>
          <BenefitsContainer>
            {benefits.map((benefit, index) => (
              <Benefit key={index}>
                <CheckIcon icon={faCheck} />
                <BenefitContent>
                  <BenefitTitle>{benefit.title}</BenefitTitle>
                  <BenefitDescription>{benefit.description}</BenefitDescription>
                </BenefitContent>
              </Benefit>
            ))}
          </BenefitsContainer>
        </Section>
      </Container>
      <Reviews />
      <ActionBar dongle={false} changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} />
    </PageContainer>
  );
};

export default ServiceTemplate;