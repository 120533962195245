import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import cleanchoicepw from "../images/cleanchoicepw.svg";
import colors from "../colors";

const StyledFooter = styled.footer`
  background: #ffffff;
  width: 100%;
  padding: 30px 0;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const TopRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLogo = styled.img`
  width: 150px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: ${colors.darkBlue};
    font-size: 14px;

    &:hover {
      color: ${colors.primaryOrange};
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  a {
    margin: 0 10px;
    font-size: 24px;
    color: ${colors.primaryBlue};

    &:hover {
      color: ${colors.primaryOrange};
    }
  }
`;

const Attribution = styled.div`
  font-size: 12px;
  color: ${colors.darkBlue};
  margin-top: 10px;

  a {
    color: ${colors.primaryOrange};
    text-decoration: none;

    &:hover {
      color: ${colors.secondaryOrange};
    }
  }
`;

const Footer = ({ changePage, setIsQuoteFormOpen }) => {
  const pageChanger = (page) => (e) => {
    e.preventDefault();
    changePage(page);
  };

  return (
    <StyledFooter>
      <StyledContainer>
        <TopRow>
          {/* Logo */}
          <Col md={3} xs={12}>
            <StyledLogo src={cleanchoicepw} alt="Clean Choice Pressure Washing" />
          </Col>

          {/* Quick Links */}
          <StyledCol md={3} xs={6}>
            <h5>Quick Links</h5>
            <a href="/" onClick={pageChanger("home")}>
              Home
            </a>
            <a href="/about" onClick={pageChanger("about")}>
              About Us
            </a>
            <a href="/gallery" onClick={pageChanger("gallery")}>
              Gallery
            </a>
            <a
              href="https://search.google.com/local/reviews?placeid=ChIJz0ajem4TEYgRDPeWqND_hYM&q=Clean+Choice+Pressure+Washing&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reviews
            </a>
          </StyledCol>

          {/* Services */}
          <StyledCol md={3} xs={6}>
            <h5>Services</h5>
            <a href="/services/housewashing" onClick={pageChanger("services/housewashing")}>
              House Washing
            </a>
            <a href="/services/roofwashing" onClick={pageChanger("services/roofwashing")}>
              Roof Washing
            </a>
            <a href="/services/windowwashing" onClick={pageChanger("services/windowwashing")}>
              Window Washing
            </a>
            <a href="/services/concretecleaning" onClick={pageChanger("services/concretecleaning")}>
              Concrete Cleaning
            </a>
          </StyledCol>

          {/* Contact Info */}
          <StyledCol md={3} xs={12}>
            <h5>Get In Touch</h5>
            <p>
              <a href="tel:+12198519299">(219) 851-9299</a>
            </p>
            <p>
              <a
                href="/quote"
                onClick={(e) => {
                  e.preventDefault();
                  setIsQuoteFormOpen(true);
                }}
              >
                Get A Quote!
              </a>
            </p>
          </StyledCol>
        </TopRow>

        {/* Social Media Icons */}
        <IconGroup>
          <a
            href="https://www.facebook.com/CleanChoice87"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.instagram.com/cleanchoicepw"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </IconGroup>

        {/* Attribution */}
        <Attribution>
          &copy; {new Date().getFullYear()} Clean Choice Pressure Washing. All rights reserved. Website & Branding by{" "}
          <a href="https://tirrell.studio">Tirrell Studio</a>.
        </Attribution>
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;