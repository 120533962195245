import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const WindowWashing = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Enhances Curb Appeal",
      description:
        "Sparkling clean windows transform your home's appearance, creating a fresh and inviting look that impresses guests and neighbors.",
    },
    {
      title: "Improves Natural Light",
      description:
        "Removing dirt, smudges, and grime allows more sunlight to enter your home, creating a brighter and more cheerful atmosphere indoors.",
    },
    {
      title: "Extends Window Lifespan",
      description:
        "Regular cleaning prevents the buildup of harmful contaminants that can cause scratches, etching, and degradation over time.",
    },
    {
      title: "Increases Energy Efficiency",
      description:
        "Clean windows improve heat reflection and insulation, helping regulate indoor temperatures and reduce energy costs.",
    },
    {
      title: "Saves Time and Effort",
      description:
        "Professional window washing is thorough and efficient, saving you the hassle of doing it yourself and ensuring a streak-free finish.",
    },
    {
      title: "Protects Investment",
      description:
        "Regular maintenance keeps your windows in top condition, preserving their value and avoiding costly replacements.",
    },
  ];

  return (
    <ServiceTemplate
      title="See Clearly with Sparkling Windows"
      subtitle="Our professional Window Washing services bring a streak-free shine to your windows, enhancing both the beauty and functionality of your home."
      seeMoreLink="/gallery"
      sectionTitle="Why Choose Professional Window Washing?"
      sectionDescription="Clean windows not only improve the look of your home but also bring in more natural light and enhance energy efficiency. Here's why our window washing services are the right choice for your home:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/window_washing.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default WindowWashing;