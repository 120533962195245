import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const GraffitiRustRemoval = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Restores Property Appearance",
      description:
        "Graffiti and rust stains can tarnish the appearance of your property. Professional removal restores surfaces to their original clean and polished look.",
    },
    {
      title: "Protects Surfaces",
      description:
        "Expert techniques prevent damage to surfaces during removal, ensuring the integrity of walls, fences, or other affected areas.",
    },
    {
      title: "Increases Curb Appeal",
      description:
        "Clean and graffiti-free properties look well-maintained, creating a positive impression on visitors, clients, and neighbors.",
    },
    {
      title: "Prevents Permanent Staining",
      description:
        "Quick removal of graffiti and rust stains prevents them from becoming permanent, reducing the need for costly repairs or replacements.",
    },
    {
      title: "Supports Community Pride",
      description:
        "Removing unsightly graffiti helps maintain a clean and welcoming environment, enhancing the pride and safety of your neighborhood.",
    },
    {
      title: "Eco-Friendly Solutions",
      description:
        "Our removal methods use environmentally safe products to protect the surrounding area while achieving excellent results.",
    },
  ];

  return (
    <ServiceTemplate
      title="Erase the Unwanted, Restore the Beauty"
      subtitle="Our Graffiti & Rust Removal services eliminate unsightly marks and stains, bringing back the clean and professional appearance of your property."
      seeMoreLink="/gallery"
      sectionTitle="Why Choose Graffiti & Rust Removal?"
      sectionDescription="Graffiti and rust can significantly impact the appearance and value of your property. Removing them promptly not only restores the aesthetics but also protects surfaces from further damage. Here’s why our services are essential:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/graffiti_rust_removal.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default GraffitiRustRemoval;