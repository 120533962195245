import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-bootstrap";
import colors from "../colors";

const ReviewContainer = styled.section`
  padding: 40px 20px;
  background-color: #ffffff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
`;

const CarouselWrapper = styled.div`
  position: relative;
  max-width: 650px;
  margin: 0 auto;
`;

const StyledCarousel = styled(Carousel)`
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  .carousel-control-prev {
    left: -40px;
  }

  .carousel-control-next {
    right: -40px;
  }

  .carousel-indicators button {
    filter: invert(0.25);
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(0.5);
  }

  @media (max-width: 768px) {
    .carousel-control-prev {
      left: -10px;
    }

    .carousel-control-next {
      right: -10px;
    }
  }
`;

const Review = styled.div`
  padding: 20px;
  background-color: ${colors.offWhite};
  margin: 0 auto;
  max-width: 600px;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 300px;
  }
`;

const Stars = styled.div`
  margin-bottom: 15px;
`;

const ReviewText = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  font-style: italic;
`;

const Reviewer = styled.h4`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`;

const StyledStarIcon = styled(FontAwesomeIcon)`
  color: #FFD700;
  font-size: 20px;
  margin: 0 2px;
`;

const SeeMoreButton = styled.a`
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${colors.primaryOrange};
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.secondaryOrange};
  }
`;

const Reviews = () => {
	const [reviews] = useState([
    {
      text: "Highly recommend Clean Choice pressure washing! I called around atleast 6-8 power washing companies. Some never even got back to me, and all other quotes were significantly higher than Clean Choice! Isai didn’t a great job and was extremely responsive via email or text!",
      reviewer: "Kevin Tran"
    },
    {
      text: "5 star review for Isai and his team at Clean Choice Pressure Washing!  They arrived early,  were very professional, and the end results were more than I expected! In addition to removing the green algae from our vinyl siding, they were able to restore our gutters to a bright white that had turned grey in color over the past few years. Excellent job!",
      reviewer: "M Dew"
    },
    {
      text: "Clean Choice did a fantastic job on my house in the woods. Because of the location, the house’s windows, screens and skylights were in desperate need of cleaning. Isai was professional, thorough and personable. I highly recommend Clean Choice!",
      reviewer: "Cookie Weber"
    },
    {
      text: "I am very well pleased with the fine job that this man did for me today. He understood and addressed all my concerns, and I will definitely call him again in the future. His price was very reasonable, and he is an honest and honorable man. I most highly recommend him!",
      reviewer: "Lynn Lisarelli"
    },
    {
      text: "Clean Choice did an amazing job with my home. My awning use to be green which I thought that was the color but it was actually white. He even scrubbed down the back door which was black from our dogs pushing the door. I also want to add the house smelled amazing!! My husband and I would definitely recommend Clean Choice. We will always use Cleaning Choice from here on out. They care about their customer needs!!",
      reviewer: "Rebecca Scott"
    },
    {
      text: "My experience working with Isai was excellent.  He is an exceptional business person.  He is prompt, reliable, trustworthy, communicative, and meticulous in his work.  He chemically washed my roof to remove the streaks and the results are unbelievable.  I look forward to doing business with him when I need any of the other services he provides.  Thank you, Isai.  It is a pleasure to know you and support your business.",
      reviewer: "Amy Smith-Yoder"
    }
  ]);

  // useEffect(() => {
  //   fetch("https://api.tirrell.studio/cleanchoice/reviews")
  //     .then(response => {
  //       console.log(response.json());
  //       setReviews(response.json());
  //     })
  //     .catch(error => {
  //       console.error("Error fetching reviews:", error);
  //     })
  // }, []);

  return (
    <ReviewContainer>
      <Title>What Our Clients Say</Title>
      <CarouselWrapper>
        <StyledCarousel indicators={false}>
          {reviews.length > 0 && reviews.map((review, index) => (
            <Carousel.Item key={index}>
              <Review>
                <Stars>
                  <StyledStarIcon icon={faStar} />
                  <StyledStarIcon icon={faStar} />
                  <StyledStarIcon icon={faStar} />
                  <StyledStarIcon icon={faStar} />
                  <StyledStarIcon icon={faStar} />
                </Stars>
                <ReviewText>"{review.text}"</ReviewText>
                <Reviewer>- {review.reviewer}</Reviewer>
              </Review>
            </Carousel.Item>
          ))}
        </StyledCarousel>
      </CarouselWrapper>
      <SeeMoreButton
        href="https://search.google.com/local/reviews?placeid=ChIJz0ajem4TEYgRDPeWqND_hYM&q=Clean+Choice+Pressure+Washing&hl=en&gl=US"
        target="_blank"
        rel="noopener noreferrer"
      >
        See More Reviews
      </SeeMoreButton>
    </ReviewContainer>
  );
};

export default Reviews;
