import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const ConcreteCleaning = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Restores Original Appearance",
      description:
        "Professional concrete cleaning removes stains, grime, and discoloration, restoring your concrete surfaces to their original look.",
    },
    {
      title: "Enhances Safety",
      description:
        "Removing algae, mold, and slippery residues reduces the risk of slips and falls, creating a safer environment for everyone.",
    },
    {
      title: "Prevents Surface Damage",
      description:
        "Regular cleaning prevents buildup of harmful substances that can degrade the surface over time, extending the lifespan of your concrete.",
    },
    {
      title: "Improves Curb Appeal",
      description:
        "A clean driveway, sidewalk, or patio enhances the overall appearance of your property, leaving a lasting impression on visitors.",
    },
    {
      title: "Increases Property Value",
      description:
        "Well-maintained concrete surfaces contribute to your home's value, making it more attractive to potential buyers or tenants.",
    },
    {
      title: "Eco-Friendly Solutions",
      description:
        "We use environmentally safe cleaning methods to ensure a thorough clean without harming the surrounding area or vegetation.",
    },
  ];

  return (
    <ServiceTemplate
      title="Restore and Protect Your Concrete"
      subtitle="Our Concrete Cleaning services eliminate tough stains, dirt, and algae, rejuvenating your concrete surfaces and protecting their longevity."
      seeMoreLink="/gallery"
      sectionTitle="Why Professional Concrete Cleaning Matters"
      sectionDescription="Concrete surfaces, such as driveways, sidewalks, and patios, are exposed to the elements and prone to staining and wear. Professional cleaning restores their look and enhances their durability. Here’s why it’s essential:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/concrete_cleaning.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default ConcreteCleaning;