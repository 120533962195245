import React from 'react';
import styled from 'styled-components';

import colors from '../colors';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  color: #333;
  padding: 20px;
  background: ${colors.offWhite}
`;

const Title = styled.h1`
  font-size: 72px;
  margin: 0;
  color: ${colors.primaryOrange};
`;

const Message = styled.p`
  font-size: 24px;
  margin: 20px 0;
`;

const HomeButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: ${colors.primaryBlue};
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`;

const NotFound = ({ changePage }) => {
  return (
    <NotFoundContainer>
      <Title>404</Title>
      <Message>Oops! The page you're looking for doesn't exist.</Message>
      <HomeButton onClick={() => changePage('home')}>Go Back Home</HomeButton>
    </NotFoundContainer>
  );
};

export default NotFound;
