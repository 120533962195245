import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const BuildingRestoration = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Enhances Building Aesthetics",
      description:
        "Restoration removes grime, stains, and weathering, improving curb appeal and leaving a positive impression on clients and visitors.",
    },
    {
      title: "Extends Lifespan of Building Materials",
      description:
        "Regular maintenance prevents harmful substances from degrading building materials, extending their lifespan and reducing the need for replacements.",
    },
    {
      title: "Prevents Costly Repairs",
      description:
        "Addressing minor issues early during restoration helps avoid major problems, saving on expensive future repairs.",
    },
    {
      title: "Improves Energy Efficiency",
      description:
        "Cleaner windows and roofs improve natural light and heat reflection, reducing energy costs for lighting and cooling.",
    },
    {
      title: "Maintains Property Value",
      description:
        "Regular restoration ensures the building retains its value and appeal, making it attractive to potential buyers or tenants.",
    },
    {
      title: "Ensures Regulatory Compliance",
      description:
        "Restoration helps meet safety and environmental standards, avoiding potential fines and legal challenges.",
    },
  ];

  return (
    <ServiceTemplate
      title="Renew The Beauty"
      subtitle="We recognize the transformative impact of Building Restoration Services. Our mission is to breathe new life into structures that have weathered with time, grime, and the elements."
      seeMoreLink="/gallery"
      sectionTitle="Why Commercial Building Restoration?"
      sectionDescription="Commercial building restoration is essential to maintaining both the aesthetic appeal and structural integrity of business properties. This process, which includes the comprehensive cleaning of windows, roofs, walls, and gutters, not only preserves the building's appearance but also enhances its functionality. Here are six key benefits of commercial building restoration:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/building_restoration.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default BuildingRestoration;