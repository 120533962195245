const colors = {
  offWhite: '#f3f5f8',
  primaryBlue: '#3567BF',
  darkBlue: '#304C88',
  secondaryBlue: '#6CA0D2',
  primaryOrange: '#E8A14A',
  secondaryOrange: '#C7791A',
  paleGreen: '#C9ECCB',
  primaryGreen: '#8ECB8B',
  lightGrey: '#D9D9D9',
  darkGrey: '#5F5F5F'
};

export default colors;