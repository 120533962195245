import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import colors from "../colors";
import { PageContainer } from "../components/Reusables";
import ActionBar from "../components/ActionBar";
import Reviews from "../components/Reviews";

const WhyUsContainer = styled.section`
  width: 100%;
  padding: 60px 60px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
`;

const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;

const Point = styled.div`
  background: ${colors.offWhite};
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PointTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  color: ${colors.primaryOrange};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    border-bottom: 1px solid ${colors.primaryOrange};
  }
`;

const PointDescription = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.5;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  margin-bottom: -5px;
  font-size: 40px;
  color: green;
`;

const WhyUs = ({ changePage }) => {
  return (
    <WhyUsContainer>
      <Title>Why Choose Us</Title>
      <PointsContainer>
        <Point>
          <PointTitle>
            <CheckIcon icon={faCheck} />
            <p>Safe and Effective Cleaning</p>
          </PointTitle>
          <PointDescription>
            Our soft washing process safely removes mold, algae, and grime without damaging your surfaces, keeping your property in top condition.
          </PointDescription>
        </Point>
        <Point>
          <PointTitle>
            <CheckIcon icon={faCheck} />
            <p>Reliable and Consistent Service</p>
          </PointTitle>
          <PointDescription>
            We’re dedicated to showing up on time, every time, and delivering a consistent, high-quality cleaning experience you can count on.
          </PointDescription>
        </Point>
        <Point>
          <PointTitle>
            <CheckIcon icon={faCheck} />
            <p>Customer-Centered Service</p>
          </PointTitle>
          <PointDescription>
            Your satisfaction is our top priority. We tailor our services to meet your specific needs and provide follow-up support.
          </PointDescription>
        </Point>
        <Point>
          <PointTitle>
            <CheckIcon icon={faCheck} />
            <p>Proven Expertise and Results</p>
          </PointTitle>
          <PointDescription>
            With years of experience, our skilled team guarantees top-quality results, backed by countless positive reviews and referrals.
          </PointDescription>
        </Point>
      </PointsContainer>
    </WhyUsContainer>
  );
};

const ServicesContainer = styled.section`
  width: 100%;
  padding-top: 60px;
  background-color: ${colors.offWhite};
  text-align: center;
`;

const ServicesGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;

const ServiceItem = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const LearnMoreButton = styled.a`
  text-decoration: none;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${colors.secondaryOrange};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.primaryOrange};
  }
`;

const Services = ({
  changePage
}) => {
  const pageChanger = (page) =>{
    return (e) => {
      e.preventDefault()
      changePage(page)
    }
  }
  return (
    <ServicesContainer>
      <Title>Our Services</Title>
      <ServicesGrid>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/house_washing.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>House Washing</ServiceTitle>
            <LearnMoreButton href="/services/housewashing" onClick={pageChanger('services/housewashing')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/window_washing.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>Window Washing</ServiceTitle>
            <LearnMoreButton href="/services/windowwashing" onClick={pageChanger('services/windowwashing')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/roof_washing.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>Roof Washing</ServiceTitle>
            <LearnMoreButton href="/services/roofwashing" onClick={pageChanger('services/roofwashing')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/concrete_cleaning.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>Concrete Cleaning</ServiceTitle>
            <LearnMoreButton  href="/services/concretecleaning" onClick={pageChanger('services/concretecleaning')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/gutter_brightening.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>Gutter Brightening</ServiceTitle>
            <LearnMoreButton href="/services/gutterbrightening" onClick={pageChanger('services/gutterbrightening')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
        <ServiceItem image={'https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/deck_fence_cleaning.jpg'}>
          <Overlay className="overlay">
            <ServiceTitle>Deck & Fence Cleaning</ServiceTitle>
            <LearnMoreButton href="/services/deckandfence" onClick={pageChanger('services/deckandfence')}>
              Learn More
            </LearnMoreButton>
          </Overlay>
        </ServiceItem>
      </ServicesGrid>
    </ServicesContainer>
  );
};

const Home = ({
  changePage,
  setIsQuoteFormOpen
}) => {
  return (
    <PageContainer>
      <WhyUs />
      <Services changePage={changePage} />
      <Reviews />
      <ActionBar dongle={false} changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} />
    </PageContainer>
  );
};

export default Home;
