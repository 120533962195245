import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const RoofWashing = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Prevents Roof Damage",
      description:
        "Roof washing removes harmful debris, moss, and algae, preventing them from degrading shingles and other roofing materials.",
    },
    {
      title: "Extends Roof Lifespan",
      description:
        "Regular washing reduces wear and tear on your roof, helping it last longer and delaying costly replacements.",
    },
    {
      title: "Improves Energy Efficiency",
      description:
        "A clean roof reflects sunlight better, reducing heat absorption and lowering your cooling costs during warmer months.",
    },
    {
      title: "Enhances Home Appearance",
      description:
        "Removing black streaks, moss, and stains restores your roof's original look, improving your home's overall curb appeal.",
    },
    {
      title: "Prevents Mold Growth",
      description:
        "Eliminating moisture-retaining debris helps prevent mold and mildew from growing, promoting a healthier environment.",
    },
    {
      title: "Increases Property Value",
      description:
        "A well-maintained roof is a sign of a cared-for home, making it more attractive to potential buyers and maintaining its value.",
    },
  ];

  return (
    <ServiceTemplate
      title="Protect and Beautify Your Roof"
      subtitle="Our professional Roof Washing services help extend the life of your roof, improve its appearance, and prevent costly repairs. Keep your home safe and looking its best."
      seeMoreLink="/gallery"
      sectionTitle="Why Regular Roof Washing is Essential"
      sectionDescription="Your roof is your home's first line of defense against the elements, and keeping it clean is crucial for its longevity and efficiency. Here's why roof washing should be part of your maintenance plan:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/roof_washing.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default RoofWashing;