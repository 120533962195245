import React from "react";
import { PageContainer } from "../components/Reusables";
import styled from "styled-components";
import colors from "../colors";
import ActionBar from "../components/ActionBar";

const AboutContainer = styled.section`
  width: 100%;
  padding: 60px 20px;
  text-align: center;
`;

const AboutTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${colors.primaryOrange};
`;

const OwnerImage = styled.img`
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  margin: 20px auto;
  display: block;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const AboutSection = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: justify;
  color: #555;
  line-height: 1.6;

  p {
    font-size: 20px;
  }
`;

const MissionSection = styled.div`
  background-color: ${colors.primaryOrange};
  color: white;
  padding: 40px 20px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  font-size: 20px;
`;

const MissionText = styled.p`
  font-size: 18px;
  max-width: 700px;
  margin: 0 auto;
  font-style: italic;
  line-height: 1.5;
  font-weight: bold;
`;

const About = ({
  changePage,
  setIsQuoteFormOpen
}) => {
  return (
    <PageContainer>
      <AboutContainer>
        <AboutTitle>Isai and Clean Choice Pressure Washing</AboutTitle>
        <OwnerImage
          src="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/isai_wife.jpg"
          alt="Isai Calderon - Founder of Clean Choice Pressure Washing"
        />
        <AboutSection>
          <p>
            Founded in 2017 by Isai Calderon, Clean Choice Pressure Washing was inspired by his extensive volunteer work in disaster relief across Florida and Puerto Rico. Witnessing communities' resilience and the critical role of maintenance and restoration, Isai set out to create a business that does more than just clean – it revitalizes.
          </p>
          <p>
            Based in Northwest Indiana, Clean Choice Pressure Washing serves residential and commercial clients, offering a wide range of services including siding, roofs, windows, decks, and concrete cleaning. We are committed to preserving not only the beauty but also the structural integrity and value of your property.
          </p>
        </AboutSection>
        <MissionSection>
          <MissionText>
            “Our mission is to provide unmatched cleaning services with integrity, reliability, and community support at the core of everything we do.”
          </MissionText>
        </MissionSection>
        <AboutSection>
          <p>
            Equipped with the latest in eco-friendly cleaning technology, our team ensures safe and effective removal of mold, algae, and grime from all surfaces. From the first consultation to the completion of every project, we guarantee results that speak for themselves and a commitment to excellence that our clients rely on.
          </p>
          <p>
            We take pride in our work and continuously seek to exceed expectations by delivering a professional, seamless experience that leaves your property looking refreshed and well-maintained. Our passion for cleanliness and dedication to client satisfaction have earned us countless positive reviews and a loyal client base that we’re proud to serve.
          </p>
        </AboutSection>
      </AboutContainer>
      <ActionBar changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} dongle={false} />
    </PageContainer>
  );
};

export default About;