import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

import colors from "../colors";
import Altcha from "../components/Altcha";
import getaquotebg from "../images/getaquotebg.svg";

// Animation for water rushing in
const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

// Main container for the modal
const QuoteContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.5s ease, visibility 0.5s ease;
`;

// Water background animation
const WaterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${getaquotebg}) repeat-x;
  background-size: cover;
  animation: ${({ isOpen }) => (isOpen ? slideUp : slideDown)} 0.5s ease-out forwards;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormHeading = styled.h2`
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 5px;
`;

// Wrapper for the form content
const FormWrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${colors.offWhite};
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  text-align: center;

  label {
    margin-bottom: 8px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    label {
      margin-bottom: 4px;
    }
  }
`;

// Form title styling
const FormTitle = styled.h2`
  color: ${colors.primaryOrange};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

// Progress bar styling
const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const ProgressStep = styled.div`
  flex-grow: 1;
  height: 8px;
  background-color: ${({ isActive }) => (isActive ? colors.primaryGreen : colors.lightGrey)};
  margin: 0 5px;
  transition: background-color 0.3s ease;
  border-radius: 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

// Form fields styling
const FormField = styled.div`
  margin-bottom: 20px;

  input,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid ${colors.lightGrey};
    border-radius: 8px;
    font-size: 16px;
    background-color: ${({ hasValue }) => (hasValue ? colors.paleGreen : "white")};
    transition: background-color 0.3s ease;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

// Button styling
const SubmitButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.primaryGreen)};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ disabled }) => (!disabled && colors.primaryBlue)};
  }
`;

const PreviousButton = styled(SubmitButton)`
  background-color: ${colors.primaryOrange};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

// Checkbox container styling
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 40px 0;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  @media (max-width: 768px) {
    top: 5px;
    right: 0;
  }
`;

const SubscribeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const GetAQuote = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    newsletter: true
  });
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const altchaRef = useRef(null);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const validateStep = () => {
    switch (step) {
      case 1:
        return formData.serviceType;
      case 2:
        return (
          formData.servicesNeeded &&
          formData.completionTime &&
          (formData.serviceType === "residential"
            ? formData.stories && formData.exteriorType
            : formData.propertyOwner && formData.propertyType && formData.recurringService)
        );
      case 3:
        return (
          formData.firstName &&
          formData.lastName &&
          formData.email &&
          formData.phone &&
          formData.altchaToken
        );
      default:
        return false;
    }
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setError("");
      setStep((prevStep) => prevStep + 1);
    } else {
      setError("Please fill in all required fields.");
    }
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) {
      setError("Please fill in all required fields.");
      return;
    }
    console.log(formData);
    fetch("https://api.tirrell.studio/cleanchoice/form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          setError("There was an unexpected issue sending your message. Please try again or call us at 219-851-9299 to get your quote.");
        }
      })
      .catch((error) => {
        setError("There was an unexpected issue sending your message. Please try again or call us at 219-851-9299 to get your quote.");
      });
  };

  const renderStepContent = () => {
    if (isSubmitted) {
      return <SuccessMessage>Thank you for your choosing us! You will hear from us shortly.</SuccessMessage>;
    }

    switch (step) {
      case 1:
        return (
          <>
            <FormTitle>Select Service Type</FormTitle>
            <FormField hasValue={formData.serviceType}>
              <select
                value={formData.serviceType || ""}
                onChange={(e) => handleInputChange("serviceType", e.target.value)}
              >
                <option value="">Select Service Type</option>
                <option value="residential">Residential</option>
                <option value="commercial">Commercial</option>
              </select>
            </FormField>
          </>
        );
      case 2:
        return (
          <>
            <FormTitle>
              {formData.serviceType === "residential" ? "Residential" : "Commercial"} Service Details
            </FormTitle>
            <FormField hasValue={formData.servicesNeeded}>
              <select
                value={formData.servicesNeeded || ""}
                onChange={(e) => handleInputChange("servicesNeeded", e.target.value)}
              >
                <option value="">Select Service</option>
                {formData.serviceType === "residential" ? (
                  <>
                    <option value="House Washing">House Washing</option>
                    <option value="Roof Cleaning">Roof Cleaning</option>
                    <option value="Concrete Cleaning">Concrete Cleaning</option>
                    <option value="Deck Washing">Deck Washing</option>
                    <option value="Fence Cleaning">Fence Cleaning</option>
                    <option value="Gutter Cleaning">Gutter Cleaning</option>
                    <option value="Window Cleaning">Window Cleaning</option>
                  </>
                ) : (
                  <>
                    <option value="Building Cleaning">Building Cleaning</option>
                    <option value="Concrete Cleaning">Concrete Cleaning</option>
                    <option value="Deck Cleaning">Deck Cleaning</option>
                    <option value="Fence Cleaning">Fence Cleaning</option>
                    <option value="Gutter Cleaning">Gutter Cleaning</option>
                    <option value="Window Cleaning">Window Cleaning</option>
                  </>
                )}
              </select>
            </FormField>
            {formData.serviceType === "residential" ? (
              <>
                <FormField hasValue={formData.stories}>
                  <input
                    type="number"
                    placeholder="Number of Stories"
                    value={formData.stories || ""}
                    onChange={(e) => handleInputChange("stories", e.target.value)}
                  />
                </FormField>
                <FormField hasValue={formData.exteriorType}>
                  <select
                    value={formData.exteriorType || ""}
                    onChange={(e) => handleInputChange("exteriorType", e.target.value)}
                  >
                    <option value="">Exterior Type</option>
                    <option value="Vinyl">Vinyl</option>
                    <option value="Wood">Wood</option>
                    <option value="Brick">Brick</option>
                    <option value="Aluminum">Aluminum</option>
                    <option value="Other">Other</option>
                  </select>
                </FormField>
              </>
            ) : (
              <>
                <FormField hasValue={formData.propertyOwner}>
                  <select
                    value={formData.propertyOwner || ""}
                    onChange={(e) => handleInputChange("propertyOwner", e.target.value)}
                  >
                    <option value="">Are You the Property Owner?</option>
                    <option value="Yes, I'm the Property Owner">Yes, I'm the Property Owner</option>
                    <option value="No, I'm a Manager">No, I'm a Manager</option>
                    <option value="No, I'm an Employee">No, I'm an Employee</option>
                  </select>
                </FormField>
                <FormField hasValue={formData.propertyType}>
                  <select
                    value={formData.propertyType || ""}
                    onChange={(e) => handleInputChange("propertyType", e.target.value)}
                  >
                    <option value="">Type of Property</option>
                    <option value="Retail Storefront">Retail Storefront</option>
                    <option value="Shopping Center">Shopping Center</option>
                    <option value="Bank">Bank</option>
                    <option value="Office Building">Office Building</option>
                    <option value="Condo/Townhome/Apartment Building">
                      Condo/Townhome/Apartment Building
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </FormField>
              </>
            )}
            <FormField hasValue={formData.completionTime}>
              <select
                value={formData.completionTime || ""}
                onChange={(e) => handleInputChange("completionTime", e.target.value)}
              >
                <option value="">Urgency</option>
                <option value="Immediately">Immediately</option>
                <option value="2-5 Days">2-5 Days</option>
                <option value="1-2 Weeks">1-2 Weeks</option>
                <option value="2-4 Weeks">2-4 Weeks</option>
                <option value="Just Getting Estimates">Just Getting Estimates</option>
              </select>
            </FormField>
            {formData.serviceType === "commercial" && (
              <FormField hasValue={formData.recurringService}>
                <select
                  value={formData.recurringService || ""}
                  onChange={(e) => handleInputChange("recurringService", e.target.value)}
                >
                  <option value="">Set Up Recurring Service</option>
                  <option value="No, One Time Service">No, One Time Service</option>
                  <option value="Yes, Monthly">Yes, Monthly</option>
                  <option value="Yes, Quarterly">Yes, Quarterly</option>
                  <option value="Yes, Semi-Annually">Yes, Semi-Annually</option>
                  <option value="Yes, Annually">Yes, Annually</option>
                  <option value="Other">Other</option>
                </select>
              </FormField>
            )}
          </>
        );
      case 3:
        return (
          <>
            <FormTitle>Contact Information</FormTitle>
            <FormField hasValue={formData.firstName}>
              <input
                type="text"
                placeholder="First Name"
                value={formData.firstName || ""}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
              />
            </FormField>
            <FormField hasValue={formData.lastName}>
              <input
                type="text"
                placeholder="Last Name"
                value={formData.lastName || ""}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
              />
            </FormField>
            <FormField hasValue={formData.email}>
              <input
                type="email"
                placeholder="Email"
                value={formData.email || ""}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </FormField>
            <FormField hasValue={formData.phone}>
              <input
                type="tel"
                placeholder="Phone Number"
                value={formData.phone || ""}
                onChange={(e) => handleInputChange("phone", e.target.value)}
              />
            </FormField>
            <FormField hasValue={formData.serviceAddress}>
              <input
                type="text"
                placeholder="Servicing Street Address"
                value={formData.serviceAddress || ""}
                onChange={(e) => handleInputChange("serviceAddress", e.target.value)}
                required
              />
            </FormField>
            <FormField hasValue={formData.serviceState}>
              <input
                type="text"
                placeholder="Servicing State"
                value={formData.serviceState || ""}
                onChange={(e) => handleInputChange("serviceState", e.target.value)}
                required
              />
            </FormField>
            <FormField hasValue={formData.serviceCity}>
              <input
                type="text"
                placeholder="Servicing City/Township"
                value={formData.serviceCity || ""}
                onChange={(e) => handleInputChange("serviceCity", e.target.value)}
                required
              />
            </FormField>
            <CheckboxContainer>
              <SubscribeContainer>
                <Checkbox
                  type="checkbox"
                  checked={formData.newsletter}
                  onChange={(e) => handleInputChange("newsletter", e.target.checked)}
                />
                Subscribe to Newsletter
              </SubscribeContainer>
              <Altcha
                ref={altchaRef}
                onStateChange={(e) => handleInputChange("altchaToken", e.detail.payload)}
              />
            </CheckboxContainer>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <QuoteContainer isOpen={isOpen}>
      <WaterBackground isOpen={isOpen}>
        {step === 1 && <FormHeading>Fill out the form to get a speedy quote!</FormHeading>}
        <FormWrapper>
          <CloseButton onClick={() => setIsOpen(false)}>&times;</CloseButton>
          {renderStepContent()}
          <ProgressBar>
            <ProgressStep isActive={step >= 1} />
            <ProgressStep isActive={step >= 2} />
            <ProgressStep isActive={step === 3} />
          </ProgressBar>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!isSubmitted && (
            <ButtonContainer>
              {step > 1 && <PreviousButton onClick={handlePreviousStep}>Back</PreviousButton>}
              {step < 3 && (
                <SubmitButton disabled={!validateStep()} onClick={handleNextStep}>
                  Next
                </SubmitButton>
              )}
              {step === 3 && (
                <SubmitButton disabled={!validateStep()} onClick={handleSubmit}>
                  Submit
                </SubmitButton>
              )}
            </ButtonContainer>
          )}
        </FormWrapper>
      </WaterBackground>
    </QuoteContainer>
  );
};

export default GetAQuote;