import React from "react";
import ServiceTemplate from "../../components/ServiceTemplate";

const DeckFenceCleaning = ({ changePage, setIsQuoteFormOpen }) => {
  const benefits = [
    {
      title: "Restores Natural Beauty",
      description:
        "Professional cleaning removes dirt, algae, and mildew, bringing back the natural look of your wood or composite deck and fence.",
    },
    {
      title: "Prevents Rot and Decay",
      description:
        "Regular cleaning eliminates harmful buildup that can cause wood rot and compromise the integrity of your deck or fence.",
    },
    {
      title: "Extends Lifespan",
      description:
        "Proper maintenance protects your deck and fence from wear and tear, ensuring they last for years to come.",
    },
    {
      title: "Prepares for Staining or Sealing",
      description:
        "A clean surface ensures that stains, paints, or sealants adhere properly, providing better protection and a more polished finish.",
    },
    {
      title: "Improves Safety",
      description:
        "Removing slippery algae and mildew makes your deck safer to walk on, reducing the risk of slips and falls.",
    },
    {
      title: "Boosts Property Value",
      description:
        "A clean and well-maintained deck and fence enhance your property’s curb appeal and overall value, making it more attractive to potential buyers.",
    },
  ];

  return (
    <ServiceTemplate
      title="Bring New Life to Your Deck and Fence"
      subtitle="Our Deck & Fence Cleaning services remove dirt, mildew, and grime, restoring their beauty and protecting them from damage caused by the elements."
      seeMoreLink="/gallery"
      sectionTitle="Why Deck & Fence Cleaning is Essential"
      sectionDescription="Decks and fences are constantly exposed to weather and wear, leading to dirt buildup and potential damage. Regular cleaning not only revitalizes their appearance but also protects and extends their lifespan. Here’s why you should prioritize this service:"
      exampleImage="https://s3.us-east-1.amazonaws.com/cleanchoicepw.com-assets/deck_fence_cleaning.jpg"
      benefits={benefits}
      changePage={changePage}
      setIsQuoteFormOpen={setIsQuoteFormOpen}
    />
  );
};

export default DeckFenceCleaning;