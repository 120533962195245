import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import NotFound from './pages/NotFound';
import GetAQuote from './pages/GetAQuote';

import HouseWashing from './pages/services/HouseWashing';
import RoofWashing from './pages/services/RoofWashing';
import WindowWashing from './pages/services/WindowWashing';
import GutterBrightening from './pages/services/GutterBrightening';
import ConcreteCleaning from './pages/services/ConcreteCleaning';
import DeckFenceCleaning from './pages/services/DeckFenceCleaning';
import BuildingRestoration from './pages/services/BuildingRestoration';
import GraffitiRustRemoval from './pages/services/GraffitiRustRemoval';

const pages = [
  'home',
  'about',
  'gallery',
  'services/housewashing',
  'services/roofwashing',
  'services/windowwashing',
  'services/gutterbrightening',
  'services/concretecleaning',
  'services/deckandfence',
  'services/buildingrestoration',
  'services/graffitirustremoval',
];

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export default function App() {
  return (
    <Router>
      <PageWrapper />
    </Router>
  );
}

function PageWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(
    pages.includes(location.pathname.substring(1)) ? location.pathname.substring(1) : 'home'
  );
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    if (pages.includes(currentPath)) {
      setActivePage(currentPath);
    } else {
      setActivePage('home');
    }
  }, [location]);

  const changePage = (page) => {
    setActivePage(page);
    if (page === 'home') navigate('/');
    else navigate(`/${page}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AppContainer>
      <GetAQuote isOpen={isQuoteFormOpen} setIsOpen={setIsQuoteFormOpen} />
      <Header setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} activePage={activePage} />
      {(pages.includes(location.pathname.substring(1)) || location.pathname === '/') &&
        activePage !== 'contact' && (
          <Hero page={activePage} changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} />
        )
      }
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/about" element={<About setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/gallery" element={<Gallery setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/housewashing" element={<HouseWashing setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/roofwashing" element={<RoofWashing setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/windowwashing" element={<WindowWashing setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/gutterbrightening" element={<GutterBrightening setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/concretecleaning" element={<ConcreteCleaning setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/deckandfence" element={<DeckFenceCleaning setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/buildingrestoration" element={<BuildingRestoration setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="/services/graffitirustremoval" element={<GraffitiRustRemoval setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
        <Route path="*" element={<NotFound setIsQuoteFormOpen={setIsQuoteFormOpen} changePage={changePage} />} />
      </Routes>

      <Footer changePage={changePage} setIsQuoteFormOpen={setIsQuoteFormOpen} />
    </AppContainer>
  );
}