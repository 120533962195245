import React from "react";
import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "altcha";
import styled from "styled-components";

const StyledAltcha = styled("altcha-widget")`
  width: 100%;
`;

const Altcha = forwardRef(
  ({ onStateChange }, ref) => {
    const widgetRef = useRef(null);
    const [value, setValue] = useState(null);

    useImperativeHandle(ref, () => {
      return {
        get value() {
          return value;
        },
      };
    }, [value]);

    useEffect(() => {
      const handleStateChange = (ev) => {
        if ("detail" in ev) {
          setValue(ev.detail.payload || null);
          onStateChange?.(ev);
        }
      };

      const { current } = widgetRef;

      if (current) {
        current.addEventListener("statechange", handleStateChange);
        return () =>
          current.removeEventListener("statechange", handleStateChange);
      }
    }, [onStateChange]);

    return (
      <StyledAltcha
        ref={widgetRef}
        hidelogo
        hidefooter
        challengeurl="https://api.tirrell.studio/altchaChallenge"
      ></StyledAltcha>
    );
  },
);

export default Altcha;
